import request from "../utils/request";
//新建产品类目
export const creatproducttype = data => {
  return request({
    method: 'POST',
    url: '/system/product/createCategory',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除产品类目
export const delprotype = data => {
  return request({
    method: 'POST',
    url: '/system/product/deleteCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取产品类目列表
export const getProList = data => {
  return request({
    method: 'POST',
    url: '/system/product/getCategories',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取产品类目详情
export const getProDetailById = data => {
  return request({
    method: 'POST',
    url: '/system/product/getCategoryDetails',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改产品类目
export const changeProType = data => {
  return request({
    method: 'POST',
    url: '/system/product/modifyCategory',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
// ===========分割线===========
//选择产品分类
export const chooseType = data => {
  return request({
    method: 'get',
    url: '/system/product/chooseCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//新建产品
export const createProduct = data => {
  return request({
    method: 'post',
    url: '/system/product/createProduct',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除产品
export const delProduct = data => {
  return request({
    method: 'post',
    url: '/system/product/deleteProduct',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取产品详情
export const getProductDetailById = data => {
  return request({
    method: 'post',
    url: '/system/product/getProductDetails',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取产品列表
export const getProductList = data => {
  return request({
    method: 'post',
    url: '/system/product/getProducts',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改产品

export const changeProduct = data => {
  return request({
    method: 'post',
    url: '/system/product/modifyProduct',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}